import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  createHttpLink,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import "dotenv/config";

// const httpLink = createHttpLink({
//   uri: `${process.env.API_URL || "http://localhost:1337"}/graphql`,
//   credentials: "include",
//   fetch,
// });

// const client = new ApolloClient({
//   link: ApolloLink.from([httpLink]),
//   cache: new InMemoryCache(),
//   credentials: "include",
// });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `${process.env.GATSBY_API_URL || "http://localhost:1337"}/graphql`,
    fetch,
  }),
});

export default client;
